{
  "name": "et-plm",
  "version": "12.36.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build && npm run remove-sourcemaps",
    "dev": "ng build --configuration development",
    "deployment": "npm run prettier && ng build && npm run sentry:sourcemaps && rm dist/*.map && git add . && git commit -m \"chore: prepare new release\" && npm run release",
    "deployment-patch": "npm run prettier && ng build && npm run sentry:sourcemaps && rm dist/*.map && git add . && git commit -m \"chore: prepare new release\" && npm run release -- patch",
    "deployment-major": "npm run prettier && ng build && npm run sentry:sourcemaps && rm dist/*.map && git add . && git commit -m \"chore: prepare new release\" && npm run release -- major",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "purge": "ngtw build --purge",
    "remove-sourcemaps": "rm dist/*.map",
    "release": "release-it --no-git.requireUpstream",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org espresso-tutorials-gmbh --project admin-plm ./dist && sentry-cli sourcemaps upload --release=${npm_package_version} --org espresso-tutorials-gmbh --project admin-plm ./dist",
    "prettier": "npx prettier --write ."
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/core": "^19.1.3",
    "@angular/animations": "^19.1.2",
    "@angular/cdk": "^19.1.0",
    "@angular/common": "^19.1.2",
    "@angular/compiler": "^19.1.2",
    "@angular/core": "^19.1.2",
    "@angular/forms": "^19.1.2",
    "@angular/material": "^19.1.0",
    "@angular/platform-browser": "^19.1.2",
    "@angular/platform-browser-dynamic": "^19.1.2",
    "@angular/router": "^19.1.2",
    "@datorama/akita": "^7.1.1",
    "@datorama/akita-ng-entity-service": "^7.0.0",
    "@espressotutorialsgmbh/espresso-tailwind-config": "^2.0.0",
    "@espressotutorialsgmbh/et-schematics": "^0.0.5",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-free-brands": "^5.1.0-3",
    "@fortawesome/fontawesome-svg-core": "^6.7.0",
    "@fortawesome/free-solid-svg-icons": "^6.7.0",
    "@fortawesome/pro-light-svg-icons": "^6.7.0",
    "@fortawesome/pro-solid-svg-icons": "^6.7.0",
    "@ngneat/transloco": "^4.2.6",
    "@ngneat/transloco-locale": "^4.0.1",
    "@ngstack/code-editor": "^5.1.0",
    "@release-it/conventional-changelog": "^8.0.1",
    "@sentry/angular-ivy": "^7.120.3",
    "@sentry/cli": "^2.21.5",
    "@sentry/tracing": "^7.120.3",
    "@swimlane/ngx-charts": "^20.5.0",
    "@types/file-saver": "^2.0.7",
    "angularx-qrcode": "^16.0.2",
    "chrome-aws-lambda": "~7.0.0",
    "date-fns": "^2.29.1",
    "date-fns-tz": "^1.3.6",
    "file-saver": "^2.0.5",
    "hotkeys-js": "^3.10.0",
    "html-to-image": "^1.11.11",
    "html2canvas": "^1.4.1",
    "html5-qrcode": "^2.3.8",
    "inter-ui": "^3.19.3",
    "marked": "^5.0.2",
    "monaco-editor": "^0.33.0",
    "ngx-colors": "^3.5.2",
    "ngx-cookie-service": "^17.0.0",
    "ngx-filesize": "^3.0.2",
    "ngx-image-compress": "^15.1.6",
    "ngx-quill": "^26.0.8",
    "prettier-plugin-tailwindcss": "^0.6.1",
    "primeng": "^16.8.0",
    "puppeteer-core": "~7.0.0",
    "pusher-js": "^8.4.0-rc2",
    "quill": "^2.0.2",
    "rxjs": "^7.8.0",
    "slugify": "^1.6.6",
    "tslib": "^2.0.0",
    "uuid": "^11.0.5",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.3",
    "@angular/cli": "^19.1.3",
    "@angular/compiler-cli": "^19.1.2",
    "@datorama/akita-ng-router-store": "^7.0.0",
    "@datorama/akita-ngdevtools": "^7.0.0",
    "@tailwindcss/forms": "^0.5.2",
    "@types/jasmine": "^4.0.3",
    "@types/jasminewd2": "^2.0.10",
    "@types/node": "^12.11.1",
    "autoprefixer": "^10.2.6",
    "jasmine-core": "^4.1.1",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "~6.3.17",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "^5.0.1",
    "karma-jasmine-html-reporter": "^2.0.0",
    "postcss": "^8.3.0",
    "postcss-import": "13.0.0",
    "postcss-loader": "4.0.4",
    "postcss-scss": "3.0.4",
    "prettier": "^3.3.1",
    "protractor": "~7.0.0",
    "release-it": "^17.0.0",
    "tailwindcss": "^3.3.5",
    "typescript": "~5.5.4"
  },
  "release-it": {
    "plugins": {
      "@release-it/conventional-changelog": {
        "preset": "angular",
        "infile": "CHANGELOG.md"
      }
    }
  },
  "overrides": {
    "angularx-qrcode": {
      "@angular/core": "$@angular/core"
    },
    "ngx-filesize": {
      "@angular/core": "$@angular/core",
      "@angular/common": "$@angular/common"
    },
    "primeng": {
      "@angular/core": "$@angular/core",
      "@angular/common": "$@angular/common",
      "@angular/forms": "$@angular/forms",
      "zone.js": "$zone.js"
    }
  }
}
