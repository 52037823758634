import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'statusColor',
	standalone: false,
})
export class StatusColorPipe implements PipeTransform {
	transform(value: string): string {
		switch (value) {
			case 'deleted':
				return 'bg-status-red-500 text-status-red-400';
			case 'canceled':
				return 'bg-status-red-500 text-status-red-400';
			case 'inactive':
				return 'bg-status-red-500 text-status-red-400';
			case 'finished':
			case 'active':
				return 'bg-status-green-500 text-status-green-400';
			case 'cancelled':
				return 'bg-status-red-500 text-status-red-400';
			case 'outdated':
				return 'bg-status-orange-500 text-status-orange-400';
			case 'postponed':
				return 'bg-status-orange-500 text-status-orange-400';
			case 'refunded':
				return 'bg-status-red-500 text-status-red-400';
			case 'in_progress':
				return 'bg-status-orange-500 text-status-orange-400';
			case 'preview':
				return 'bg-status-blue-500 text-status-blue-400';
			case 'started':
				return 'bg-status-gray-500 text-status-gray-400';
			case 'draft':
				return 'bg-status-gray-500 text-status-gray-400';
			case 'open':
				return 'bg-status-gray-500 text-status-gray-400';
			case 'pending':
				return 'bg-status-blue-500 text-status-blue-400';
			case 'payed':
				return 'bg-status-green-500 text-status-green-400';
			case 'completed':
				return 'bg-status-green-500 text-status-green-400';
			default:
				return 'bg-status-green-500 text-status-green-400';
		}
	}
}
