import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuillModule } from 'ngx-quill';
import { AccessControlDirective } from './directives/access-control.directive';
import { SecureFilePipe } from './pipes/secure-file.pipe';
import { CommonModule } from '@angular/common';
import { NgxFilesizeModule } from 'ngx-filesize';
import { StatusPipe } from './pipes/status.pipe';
import { StatusColorPipe } from './pipes/status-color.pipe';
import { SaferPipe } from './pipes/safe-url.pipe';
import { MimeTypeFallbackPipe } from './pipes/mime-type-fallback.pipe';
import { SapConnectionTypePipe } from './pipes/sap-connection-type.pipe';
import { LanguagePipe } from './pipes/language.pipe';
import { CountryPipe } from './pipes/country.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DeleteDialogComponent } from './dialogs/delete-dialog/delete-dialog.component';
import { DuplicateDialogComponent } from './dialogs/duplicate-dialog/duplicate-dialog.component';
import { SearchListPipe } from './pipes/search-list.pipe';
import { LastLoginPipe } from './pipes/last-login.pipe';
import { TypesPipe } from './pipes/types.pipe';
import { OwnerPipe } from './pipes/owner.pipe';
import { DifficultPipe } from './pipes/difficult.pipe';
import { SyncShopPipe } from './pipes/syncShop.pipe';
import { RatingCriteriaPipe } from './pipes/rating-criteria.pipe';
import { CutterPipe } from './pipes/cutter.pipe';
import { ShowOrHideProductPipe } from './pipes/show-or-hide-product.pipe';
import { LevelsPipe } from './pipes/levels.pipe';
import { EventTypePipe } from './pipes/event-type.pipe';
import { ModificationPipe } from './pipes/modification.pipe';
import { DragScrollDirective } from './directives/drag-scroll.directive';
import { AssignSubscriptionDialogComponent } from './dialogs/asign-subscription-dialog/assign-subscription-dialog.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { BillingFielValueTitlePipe } from './pipes/billing-fiel-value-title.pipe';
import { BillingFielValuePlaceholderPipe } from './pipes/billing-fiel-value-placeholder.pipe';
import { CancelDialogComponent } from './dialogs/cancel-dialog/cancel-dialog.component';
import { SendDialogComponent } from './dialogs/send-dialog/send-dialog.component';
import { DeleteLineItemsDialogComponent } from './dialogs/delete-line-items-dialog/delete-line-items-dialog.component';
import { CalculateLogisticsDialogComponent } from './dialogs/calculate-logistis-dialog/calculate-logistics-dialog.component';
import { WysiwygPipe } from './pipes/wysiwyg.pipe';
import { CancelDocumentsDialogComponent } from './dialogs/cancel-documents-dialog/cancel-documents-dialog.component';
import { DownloadDocumentsDialogComponent } from './dialogs/download-documents-dialog/download-documents-dialog.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaymentProviderPipe } from './pipes/payment-provider.pipe';
import { DuplicateContentDialogComponent } from './dialogs/duplicate-content-dialog/duplicate-content-dialog.component';
import { SafeContextPipe } from './pipes/safe-context.pipe';
import { SendMailDialogComponent } from './dialogs/send-mail-dialog/send-mail-dialog.component';
import { OrderDialogComponent } from './dialogs/order-dialog/order-dialog.component';
import { AddRedirectResetComponent } from './dialogs/add-redirect-reset/add-redirect-reset.component';

const mods = {
	toolbar: [
		['bold', 'italic', 'underline', 'strike'],
		['blockquote'],

		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ script: 'sub' }, { script: 'super' }], // superscript/subscript

		[
			{
				color: ['#33a8e0', '#f39100', '#ffed4a', '#38c172', '#e3342f', '#ffffff', '#cccccc', '#505763', '#29303b', '#000000'],
			},
		],

		['link', 'image'],
	],
};

const components = [
	AccessControlDirective,
	DeleteDialogComponent,
	DeleteLineItemsDialogComponent,
	CancelDialogComponent,
	SendDialogComponent,
	DuplicateDialogComponent,
	AssignSubscriptionDialogComponent,
	CalculateLogisticsDialogComponent,
	DragScrollDirective,
	CancelDocumentsDialogComponent,
	DownloadDocumentsDialogComponent,
	DuplicateContentDialogComponent,
	SendMailDialogComponent,
	OrderDialogComponent,
	AddRedirectResetComponent,
];

const modules = [
	CommonModule,
	ReactiveFormsModule,
	FontAwesomeModule,
	NgxFilesizeModule,
	TranslocoRootModule,
	QuillModule.forRoot({
		modules: mods,
		customOptions: [
			{
				import: 'formats/font',
				whitelist: ['roboto-bold', 'roboto', 'roboto-light', 'roboto-thin', 'serif', 'sans-serif', 'monospace'],
			},
		],
	}),
	ClipboardModule,
	MultiSelectModule,
];

const pipes = [
	SecureFilePipe,
	StatusPipe,
	StatusColorPipe,
	SaferPipe,
	MimeTypeFallbackPipe,
	SapConnectionTypePipe,
	LanguagePipe,
	CountryPipe,
	SearchListPipe,
	LastLoginPipe,
	TypesPipe,
	OwnerPipe,
	DifficultPipe,
	SyncShopPipe,
	RatingCriteriaPipe,
	CutterPipe,
	ShowOrHideProductPipe,
	LevelsPipe,
	EventTypePipe,
	ModificationPipe,
	BillingFielValueTitlePipe,
	BillingFielValuePlaceholderPipe,
	WysiwygPipe,
	PaymentProviderPipe,
	SafeContextPipe,
];

const services = [CookieService, NgxImageCompressService];

@NgModule({
	declarations: [...components, ...pipes],
	imports: [...modules],
	// @ts-ignore
	exports: [...components, ...modules, ...pipes],
	providers: [...pipes, ...services],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedAllModule {}
