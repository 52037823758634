import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faBug, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-add-redirect-reset',
	templateUrl: './add-redirect-reset.component.html',
	styleUrls: ['./add-redirect-reset.component.scss'],
	standalone: false,
})
export class AddRedirectResetComponent implements OnInit, OnDestroy {
	public form: UntypedFormGroup;
	public isValidFormSubmitted = null;
	public error: any;

	faSuccess = faThumbsUp;
	faBug = faBug;

	constructor(
		public dialogRef: MatDialogRef<AddRedirectResetComponent>,
		private fb: UntypedFormBuilder
	) {}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy(): void {}

	initForm(): void {
		this.form = this.fb.group({
			name: ['', [Validators.required]],
		});
	}

	submitForm(): void {
		this.dialogRef.close(this.form.value);
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
