import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { TranslocoService } from '@ngneat/transloco';
import { VersioningService } from './shared/services/versioning.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: false,
})
export class AppComponent {
	public title = 'PLM';
	public returnUrl!: string | null;

	private browserLanguage: string | undefined;

	constructor(
		private route: ActivatedRoute,
		private translocoService: TranslocoService,
		private versionService: VersioningService
	) {
		registerLocaleData(localeDe);
		this.initializeApp();
	}

	initializeApp(): void {
		this.versionService.getVersion();
		this.returnUrl = this.route.snapshot.queryParams.returnUrl;

		let savedLang = localStorage.getItem('plmAppV8_language');

		if (savedLang) {
			if (savedLang !== 'de' && savedLang !== 'en') {
				savedLang = 'de';
			}

			this.browserLanguage = savedLang;
			this.translocoService.setActiveLang(savedLang);
			localStorage.setItem('plmAppV8_language', this.browserLanguage);
		} else {
			// @ts-ignore
			if (
				'en-EN' === navigator.language ||
				// @ts-ignore
				'en-UK' === navigator.userLanguage ||
				// @ts-ignore
				'en-US' === navigator.userLanguage ||
				'en' === navigator.language
			) {
				this.browserLanguage = 'en';
				this.translocoService.setActiveLang('en');
				localStorage.setItem('plmAppV8_language', this.browserLanguage);
				// @ts-ignore
			} else {
				this.browserLanguage = 'de';
				this.translocoService.setActiveLang('de');
				localStorage.setItem('plmAppV8_language', this.browserLanguage);
			}
		}

		setTimeout(() => {
			this.checkAppVersion();
		}, 2000);
	}

	checkAppVersion(): void {
		const savedVersion = this.versionService.getVersion();

		if (!savedVersion) {
			this.versionService.setVersion();
		} else {
			const currentVersion = this.versionService.getActualVersion();
			if (savedVersion !== currentVersion) {
				this.versionService.open('versioning-banner');
			}
		}
	}

	closeVersionBanner() {
		this.versionService.close('versioning-banner');
	}

	async reloadPage() {
		this.versionService.close('versioning-banner');
		this.versionService.setVersion();
		await this.versionService.handleHardReload(window.location.href);
	}
}
