import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'status',
	standalone: false,
})
export class StatusPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: unknown, args?: string) {
		switch (value) {
			case 'inactive':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-red-400 text-status-red-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg><span class="ml-2">Inaktiv</span></div>`
				);
			case 'deleted':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-red-400 text-status-red-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM160 128l41.4 0 11.3-11.3c3-3 7.1-4.7 11.3-4.7l64 0c4.2 0 8.3 1.7 11.3 4.7L310.6 128l41.4 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l192 0L338.4 354.7c-1.4 16.6-15.2 29.3-31.9 29.3l-101.1 0c-16.6 0-30.5-12.8-31.9-29.3L160 192z"/></svg><span class="ml-2">Gelöscht</span></div>`
				);
			case 'active':
			case 'finished':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-green-400 text-status-green-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg></svg><span class="ml-2">Aktiv</span></div>`
				);
			case 'outdated':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-orange-400 text-status-orange-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-112c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg><span class="ml-2">Veraltet</span></div>`
				);
			case 'in_progress':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-orange-400 text-status-orange-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192l0 384c106 0 192-86 192-192zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg><span class="ml-2">In Bearbeitung</span></div>`
				);
			case 'cancelled':
			case 'canceled':
				let str = 'Abgebrochen';
				if (args === 'billing') {
					str = 'Storniert';
				}
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-red-400 text-status-red-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg><span class="ml-2">${str}</span></div>`
				);
			case 'preview':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-blue-400 text-status-blue-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M0 256C0 114.9 114.1 .5 255.1 0C237.9 .5 224 14.6 224 32c0 17.7 14.3 32 32 32C150 64 64 150 64 256s86 192 192 192c69.7 0 130.7-37.1 164.5-92.6c-3 6.6-3.3 14.8-1 22.2c1.2 3.7 3 7.2 5.4 10.3c1.2 1.5 2.6 3 4.1 4.3c.8 .7 1.6 1.3 2.4 1.9c.4 .3 .8 .6 1.3 .9s.9 .6 1.3 .8c5 2.9 10.6 4.3 16 4.3c11 0 21.8-5.7 27.7-16c-44.3 76.5-127 128-221.7 128C114.6 512 0 397.4 0 256z"/><path class="fa-primary" d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"/></svg><span class="ml-2">Entwurf</span></div>`
				);
			case 'draft':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-gray-400 text-status-gray-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M0 256C0 114.9 114.1 .5 255.1 0C237.9 .5 224 14.6 224 32c0 17.7 14.3 32 32 32C150 64 64 150 64 256s86 192 192 192c69.7 0 130.7-37.1 164.5-92.6c-3 6.6-3.3 14.8-1 22.2c1.2 3.7 3 7.2 5.4 10.3c1.2 1.5 2.6 3 4.1 4.3c.8 .7 1.6 1.3 2.4 1.9c.4 .3 .8 .6 1.3 .9s.9 .6 1.3 .8c5 2.9 10.6 4.3 16 4.3c11 0 21.8-5.7 27.7-16c-44.3 76.5-127 128-221.7 128C114.6 512 0 397.4 0 256z"/><path class="fa-primary" d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"/></svg><span class="ml-2">Entwurf</span></div>`
				);
			case 'license_expired':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-red-400 text-status-red-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-112c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg><span class="ml-2">Lizenz abgelaufen</span></div>`
				);
			case 'open':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-orange-400 text-status-orange-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192l0 384c106 0 192-86 192-192zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg><span class="ml-2">Offen</span></div>`
				);
			case 'pending':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-gray-400 text-status-gray-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 64l0 160c0 17.7 14.3 32 32 32l160 0c0-106-86-192-192-192zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>><span class="ml-2">Ausstehend</span></div>`
				);
			case 'payed':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-green-400 text-status-green-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg><span class="ml-2">Bezahlt</span></div>`
				);
			case 'completed':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-green-400 text-status-green-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg><span class="ml-2">Abgeschlossen</span></div>`
				);
			case 'refunded':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-red-400 text-status-red-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M125.7 160l50.3 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L48 224c-17.7 0-32-14.3-32-32L16 64c0-17.7 14.3-32 32-32s32 14.3 32 32l0 51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z"/></svg><span class="ml-2">Erstattet</span></div>`
				);
			case 'sent':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-green-400 text-status-green-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0l57.4-43c23.9-59.8 79.7-103.3 146.3-109.8l13.9-10.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176 0 384c0 35.3 28.7 64 64 64l296.2 0C335.1 417.6 320 378.5 320 336c0-5.6 .3-11.1 .8-16.6l-26.4 19.8zM640 336a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L480 353.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z"/></svg><span class="ml-2">Versendet</span></div>`
				);
			case 'pre_sent':
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-blue--400 text-status-blue-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M0 256C0 114.9 114.1 .5 255.1 0C237.9 .5 224 14.6 224 32c0 17.7 14.3 32 32 32C150 64 64 150 64 256s86 192 192 192c69.7 0 130.7-37.1 164.5-92.6c-3 6.6-3.3 14.8-1 22.2c1.2 3.7 3 7.2 5.4 10.3c1.2 1.5 2.6 3 4.1 4.3c.8 .7 1.6 1.3 2.4 1.9c.4 .3 .8 .6 1.3 .9s.9 .6 1.3 .8c5 2.9 10.6 4.3 16 4.3c11 0 21.8-5.7 27.7-16c-44.3 76.5-127 128-221.7 128C114.6 512 0 397.4 0 256z"/><path class="fa-primary" d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"/></svg><span class="ml-2">Sending</span></div>`
				);
			default:
				return this.sanitizer.bypassSecurityTrustHtml(
					`<div class="rounded-lg px-1 bg-status-green-400 text-status-green-500 inline-flex flex-row items-center w-auto"><svg class="w-4 h-auto block" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg><span class="ml-2">Veröffentlicht</span></div>`
				);
		}
	}
}
