import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	faTh,
	faChartPie,
	faNetworkWired,
	faUserFriends,
	faUsers,
	faUserEdit,
	faCogs,
	faSignOut,
	faShoppingCart,
	faHandshake,
	faQuestionCircle,
	faEnvelope,
	faGraduationCap,
	faPhotoVideo,
	faArrowsRepeat1,
	faChartSimple,
	faMailbox,
	faAngleLeft,
	faFileCertificate,
	faFileInvoiceDollar,
	faSidebar,
	faScrewdriverWrench,
	faSchool,
	faThumbtack,
	faBoxesStacked,
	faBasketShopping,
	faTicket,
	faBrowser,
	faUser,
	faAngleDown,
	faLayerGroup,
	faDesktop,
	faTvRetro,
} from '@fortawesome/pro-solid-svg-icons';
import { AuthService } from '../../shared/services/auth.service';
import { environment } from '../../../environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { SessionQuery } from '../../shared/session/state';
import { PusherService } from '../../shared/services/pusher.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	standalone: false,
})
export class DashboardComponent implements OnInit, OnDestroy {
	faGrid = faTh;
	faNetwork = faNetworkWired;
	faChart = faChartPie;
	faCustomer = faUserFriends;
	faPartner = faHandshake;
	faUsers = faUsers;
	faAuthor = faUserEdit;
	faSettings = faCogs;
	faLogout = faSignOut;
	faProducts = faBoxesStacked;
	faMedia = faPhotoVideo;
	faQuestion = faQuestionCircle;
	faMail = faEnvelope;
	faLearning = faGraduationCap;
	faSubscriptions = faArrowsRepeat1;
	faAnalytics = faChartSimple;
	faPushNotifications = faMailbox;
	faCertificate = faFileCertificate;
	faCommercial = faSidebar;
	faLeft = faAngleLeft;
	faBilling = faFileInvoiceDollar;
	faTools = faScrewdriverWrench;
	faCourse = faSchool;
	faPosts = faThumbtack;
	faOrders = faBasketShopping;
	faShop = faShoppingCart;
	faTickets = faTicket;
	faLanding = faBrowser;
	faUser = faUser;
	faAngleDown = faAngleDown;
	faLearingContent = faLayerGroup;
	faPlatform = faDesktop;
	faInfoday = faTvRetro;

	public nav: Array<any>;
	public navPlm: Array<any>;

	public live = environment.live;
	public tooltip: string;

	constructor(
		private authService: AuthService,
		private pusherService: PusherService,
		private sessionQuery: SessionQuery,
		private translocoService: TranslocoService
	) {}

	ngOnInit(): void {
		this.initNav();
		setTimeout(() => {
			this.pusherService.getPusher();
			this.initListening();
		}, 2000);
	}

	ngOnDestroy(): void {
		const id = this.sessionQuery.getUserId();
		this.pusherService.unsubscribe(`private-notifications.${id}`);
	}

	logout(): void {
		this.authService.logout();
	}

	/**
	 * Listen for pusher notifications
	 */
	initListening(): void {
		const id = this.sessionQuery.getUserId();
		this.pusherService.listen(`private-notifications.${id}`, 'notification.new', (res: any) => {
			this.pusherService.handlePusherResponse(res);
		});
	}

	initNav(): void {
		this.nav = [
			{
				id: 'nav-1',
				name: 'Dashboard',
				path: '/dashboard',
				icon: this.faGrid,
				active: '',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
			},
			{
				id: 'nav-2',
				name: this.translocoService.translate('UI.user'),
				path: '/dashboard/users',
				icon: this.faUsers,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
			},
			{
				id: 'nav-3',
				name: 'Partner',
				path: '/dashboard/partner',
				icon: this.faPartner,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin'],
			},
			{
				id: 'nav-4',
				name: this.translocoService.translate('UI.customer'),
				path: '/dashboard/customer',
				icon: this.faCustomer,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
			},
			{
				id: 'nav-5',
				name: this.translocoService.translate('UI.learning_content'),
				path: '/dashboard/products',
				icon: this.faLearingContent,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
				submenu: [
					{
						id: 'nav-51',
						name: this.translocoService.translate('UI.products'),
						path: '/dashboard/products',
						icon: this.faProducts,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					},
					{
						id: 'nav-52',
						name: this.translocoService.translate('UI.learning_path'),
						path: '/dashboard/learning-path',
						icon: this.faLearning,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					},
					{
						id: 'nav-53',
						name: this.translocoService.translate('UI.courses'),
						path: '/dashboard/courses',
						icon: this.faCourse,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
					{
						id: 'nav-54',
						name: this.translocoService.translate('UI.certificates_test'),
						path: '/dashboard/certificates',
						icon: this.faCertificate,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin', 'Partner', 'Partner - Admin'],
					},
				],
			},
			{
				id: 'nav-6',
				name: this.translocoService.translate('UI.library'),
				path: '/dashboard/products',
				icon: this.faPlatform,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
				submenu: [
					{
						id: 'nav-61',
						name: 'Subscription Plans',
						path: '/dashboard/plans',
						icon: this.faSubscriptions,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
					{
						id: 'nav-62',
						name: this.translocoService.translate('UI.advertisement'),
						path: '/dashboard/commercials',
						icon: this.faCommercial,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
					{
						id: 'nav-63',
						name: 'Analytics',
						path: '/dashboard/analytics',
						icon: this.faAnalytics,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
					{
						id: 'nav-64',
						name: this.translocoService.translate('UI.emails'),
						path: '/dashboard/mails',
						icon: this.faMail,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					},
					{
						id: 'nav-65',
						name: 'Push Notifications',
						path: '/dashboard/push-notifications',
						icon: this.faPushNotifications,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
				],
			},
			{
				id: 'nav-7',
				name: 'Infoday',
				path: '/dashboard/infoday',
				icon: this.faInfoday,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin'],
				submenu: [
					{
						id: 'nav-71',
						name: 'Konferenzen',
						path: '/dashboard/infoday',
						icon: this.faNetwork,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
					{
						id: 'nav-72',
						name: 'Tickets',
						path: '/dashboard/infoday/tickets',
						icon: this.faTickets,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
					{
						id: 'nav-73',
						name: 'Kontaktpersonen',
						path: '/dashboard/infoday/sponsors/contact-person',
						icon: this.faUser,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
					{
						id: 'nav-74',
						name: 'Landingpages',
						path: '/dashboard/infoday/conference-landing-pages',
						icon: this.faLanding,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
				],
			},
			{
				id: 'nav-8',
				name: 'Webshop',
				icon: this.faShop,
				path: '/dashboard/webshop',
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin'],
				submenu: [
					{
						id: 'nav-81',
						name: 'Bestellungen',
						path: '/dashboard/webshop/orders',
						icon: this.faOrders,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
					{
						id: 'nav-82',
						name: 'Beiträge',
						path: '/dashboard/webshop/posts',
						icon: this.faPosts,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
				],
			},
			{
				id: 'nav-9',
				name: 'ARM',
				path: '/dashboard/arm',
				icon: this.faChart,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin'],
			},
			{
				id: 'nav-10',
				name: this.translocoService.translate('UI.authors'),
				path: '/dashboard/authors',
				icon: this.faAuthor,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
				submenu: [
					{
						id: 'nav-101',
						name: this.translocoService.translate('UI.authors_data'),
						path: '/dashboard/authors',
						icon: this.faUser,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					},
					{
						id: 'nav-102',
						name: 'Autoren-Abrechnung',
						path: '/dashboard/billing',
						icon: this.faBilling,
						active: 'bg-blue-600',
						roles: ['Super Admin', 'Admin'],
					},
				],
			},
			{
				id: 'nav-11',
				name: this.translocoService.translate('UI.media'),
				path: '/dashboard/media',
				icon: this.faMedia,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
			},
			{
				id: 'nav-12',
				name: 'Tools',
				path: '/dashboard/tools',
				icon: this.faTools,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin'],
			},
		];

		this.navPlm = [
			{
				id: 'nav-13',
				name: this.translocoService.translate('UI.settings'),
				path: '/dashboard/settings',
				icon: this.faSettings,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
			},
			{
				id: 'nav-14',
				name: this.translocoService.translate('UI.help'),
				path: '/dashboard/help',
				icon: this.faQuestion,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin'],
			},
		];

		this.initStyle();
	}

	initStyle(): void {
		setTimeout(() => {
			const sidebarToggle = document.querySelector('.sidebar-toogle');
			const sidebar = [].slice.call(document.querySelectorAll('.sidebar'));
			const content = document.querySelector('.content');
			const sidebarLabels = [].slice.call(document.querySelectorAll('.label'));

			if (!sidebarToggle || !sidebar || !content || !sidebarLabels) {
				return;
			}

			if (localStorage.getItem('et-plm-sidebar-small')) {
				this.tooltip = 'Menu maximieren';
				sidebarToggle.classList.add('rotate-180');
				sidebarToggle.classList.add('sidebar-toogle--small');
				sidebar.forEach((el: HTMLElement) => {
					if (el) {
						el.classList.add('sidebar--small');
					}
				});
				sidebarLabels.forEach((el: HTMLElement) => {
					if (el) {
						el.classList.add('label--small');
					}
				});
				content.classList.add('content--small');
			} else {
				this.tooltip = 'Menu minimieren';
				sidebarToggle.classList.remove('rotate-180');
				sidebarToggle.classList.remove('sidebar-toogle--small');
				sidebar.forEach((el: HTMLElement) => {
					el.classList.remove('sidebar--small');
				});
				sidebarLabels.forEach((el: HTMLElement) => {
					el.classList.remove('label--small');
				});
				content.classList.remove('content--small');
			}

			this.initSubnav();
		}, 50);
	}

	updateStyles(event: any): void {
		const sidebar = [].slice.call(document.querySelectorAll('.sidebar'));
		const sidebarLabels = [].slice.call(document.querySelectorAll('.label'));
		const content = [].slice.call(document.querySelectorAll('.content'));

		if (!sidebar || !sidebarLabels || !content) {
			return;
		}

		if (event.target.classList.contains('rotate-180')) {
			event.target.classList.remove('rotate-180');
			this.tooltip = 'Menu minimieren';
			localStorage.removeItem('et-plm-sidebar-small');
		} else {
			event.target.classList.add('rotate-180');
			this.tooltip = 'Menu maximieren';
			localStorage.setItem('et-plm-sidebar-small', 'true');
		}

		if (event.target.classList.contains('sidebar-toogle--small')) {
			event.target.classList.remove('sidebar-toogle--small');
		} else {
			event.target.classList.add('sidebar-toogle--small');
		}

		sidebar.forEach((el: HTMLElement) => {
			if (el.classList.contains('sidebar--small')) {
				el.classList.remove('sidebar--small');
			} else {
				el.classList.add('sidebar--small');
			}
		});

		sidebarLabels.forEach((el: HTMLElement) => {
			if (el.classList.contains('label--small')) {
				el.classList.remove('label--small');
			} else {
				el.classList.add('label--small');
			}
		});

		content.forEach((el: HTMLElement) => {
			if (el.classList.contains('content--small')) {
				el.classList.remove('content--small');
			} else {
				el.style.removeProperty('width');
				el.classList.add('content--small');
			}
		});
	}

	initSubnav(): void {
		const id = localStorage.getItem('et-plm-nav');
		if (id) {
			this.openSubnav(id, false);
		}
	}

	openSubnav(id: any, save: boolean = true): void {
		if (save) {
			localStorage.setItem('et-plm-nav', id);
		}

		const subnavs = document.querySelectorAll('.submenu');
		const subnav = document.getElementById(`Nav-${id}`);

		if (!subnavs || !subnav) {
			return;
		}

		if (subnav.classList.contains('block')) {
			subnav.classList.remove('block');
			subnav.classList.add('hidden');
			localStorage.removeItem('et-plm-nav');
			return;
		}

		subnavs.forEach((el: HTMLElement) => {
			el.classList.add('hidden');
			el.classList.remove('block');
		});

		subnav.classList.remove('hidden');
		subnav.classList.add('block');
	}
}
