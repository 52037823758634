import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguagePipe } from '../../pipes/language.pipe';

@Component({
	selector: 'app-generate-description',
	templateUrl: './generate-description.component.html',
	styleUrl: './generate-description.component.scss',
	standalone: false,
})
export class GenerateDescriptionComponent implements OnInit {
	public id: string;
	public language: any;
	public instructionsPlc: string =
		'Du bist ein wahrer Profi in Rechtschreibung, Grammatik und Search Engine Optimization. Du kennst dich ebenfalls bestens mit SAP aus und kannst sowohl SAP spezifische Text, Videos als auch sonstige Texte, Videos hervorragend zusammenfassen. Für die Zusammenfassung nutzt du nur die dir zur Verfügung gestellten Daten. Wie genau du den Text zusammenfassen sollst wird dir in einer Promptmitteilung vorgegeben, halte dich  strikt an diese Vorgaben. Deine Antwort erstellst du immer in der Sprache, in der das dir zur Verfügung gestellte Dokument geschrieben wurde.';
	public promptPlc: string = '';

	@ViewChild('instructions') instructions: ElementRef;
	@ViewChild('prompt') prompt: ElementRef;

	constructor(
		public dialogRef: MatDialogRef<GenerateDescriptionComponent>,
		public languagePipe: LanguagePipe,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		this.id = data.id;
		this.language = this.languagePipe.transform(data.language);
		this.promptPlc = `Ich habe dir das Transkript eines Videos als Dokument zur Verfügung gestellt. Bitte schreibe eine Zusammenfassung für dieses Video in 4 Zeilen. Nenne außerdem die vier wichtigsten Punkte als Stichpunkte. Bitte sei zurückhaltend bei der Zusammenfassung. Er soll eher neutral und nicht reißerisch klingen. Die Zusammenfassung soll in der Sprache des Transkriptes verfasst werden, in diesem Fall in ${this.language}.`;
	}

	ngOnInit(): void {}

	onGenerate(): void {
		if (!this.instructions.nativeElement.value || !this.prompt.nativeElement.value) {
			return;
		}

		this.dialogRef.close({
			instructions: this.instructions.nativeElement.value,
			prompt: this.prompt.nativeElement.value,
		});
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
